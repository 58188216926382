<template>
<div class="add_teacher">
 <el-card class="box-card">
    <el-form label-width="110px">
        <div class="title_box">用户信息</div>
           
          <div class="item_box">
              <div>
                    <el-form-item label="订单号:">{{details.coding}} </el-form-item >
                    <el-form-item label="用户名:">{{details.nickName}} </el-form-item >
                   
                  
              </div>
             <div>
                   <el-form-item >
                       <el-button type="primary"  size="mini" @click="checkOrder">查看订单</el-button>
                   </el-form-item >
                  <el-form-item label="手机号:">{{details.phone}}</el-form-item >
        
             
             </div>
             
          </div>
         <div class="title_box">售后信息</div>
          <div class="item_box">
              <div>
                    <el-form-item label="申请时间:">{{details.applyTime}} </el-form-item >
                    <el-form-item label="退款金额:">
                        <span>￥{{details.backFee}} </span>
                         <el-button type="primary" v-if="details.status == 1"  size="mini" @click="setPrice()">修改退款金额</el-button>
                    </el-form-item >
                      <el-form-item label="是否收到货:">{{details.isGetGoods}}</el-form-item >
                    <el-form-item label="退款原因:">{{details.reason}} </el-form-item >
                    <el-form-item label="说明:">{{details.remark}} </el-form-item >
                    <el-form-item label="图片:">
                          <el-image 
                          v-for="(item,index) in details.imgUrl"
                          :key="index"
                                style="width: 100px; height: 100px;margin:0 10px"
                                :src="item" 
                                :preview-src-list="details.imgUrl">
                            </el-image>
                    </el-form-item >
                    <el-form-item label="回退地址:" v-if="details.backAdd">{{details.backAdd}}</el-form-item >
                    <el-form-item label="物流信息:" v-if="details.expNo">
                        <span>{{details.expName}} {{details.expNo}}</span>
                         <el-button type="primary"  size="mini" @click="onShowExpress()">查看物流</el-button>
                    </el-form-item >
                    <el-form-item label="状态:">
                        <span>{{details.strStatus}} </span>
                           <el-button type="success" v-if="details.status == 1"  size="mini" @click="isPass(1)">通过</el-button>
                            <el-button type="primary" v-if="details.status == 1"  size="mini" @click="isPass(0)">不通过</el-button>
                            <el-button type="primary" v-if="details.status == 2 || details.status == 4"  size="mini" @click="backWarehouse">选择回退仓库</el-button>
                            <el-button type="primary" v-if="details.status == 5"  size="mini" @click="enter">确认收货</el-button>
                            <el-button type="primary" v-if="details.status == 6"  size="mini" @click="payment">打款</el-button>
                    </el-form-item >
                   
                  
              </div>
             <div>
                  
                  <el-form-item label="类型:">{{details.backType}}</el-form-item >
                  <el-form-item label="退款积分:">
                        <span>{{details.backIntegral}} </span>
                    </el-form-item >
                 
        
             
             </div>
             
          </div>
          <div class="title_box" v-if="details.masterRemark">操作记录</div>
          <div v-if="details.masterRemark" style="padding:20px">
              <div v-for="(item,index) in details.masterRemark" :key="index" style="padding:10px 0">{{item}}</div>
          </div>
         <div class="title_box" v-if="details.prodInfo.length != 0">售后商品</div>
         <div v-if="details.prodInfo.length != 0">
             <el-table
                :data="details.prodInfo"
                border
                style="width: 80%">
                <el-table-column
               
                width="180">
                 <template slot-scope="scope">
                   <el-image
                     :preview-src-list="[scope.row.showImg]"
                   
                     style="width: 120px; height: 120px"
                     :src="scope.row.showImg"
                     fit="cover">
                    </el-image>
                 </template>
                </el-table-column>
                <el-table-column
                prop="specVal"
                label="规格"
                width="180">
                </el-table-column>
                <el-table-column
                prop="unitFee"
                label="单价"
                width="180">
                </el-table-column>
                <el-table-column
                prop="count"
                label="数量"
              >
                </el-table-column>
             </el-table>

          </div>
         
           
            <el-button type="primary" v-if="details.status !=7 && details.status !=8 && details.status != 3"   @click="rejection">强制拒绝</el-button>
            <el-button type="primary"    @click="$router.go(-1)">返回</el-button>
        </el-form>
    </el-card>
    <el-dialog
    title="选择仓库"
    :visible.sync="dialogVisible"
    width="30%"
    @close="handleClose">
    <el-form :model="backForm" :rules="backFormRules" ref="backFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="选择仓库" prop="warehouseId">
            <el-select v-model="backForm.warehouseId" placeholder="请选择">
                <el-option
                v-for="item in warehouseList"
                :key="item.warehouseId"
                :label="item.warehouseName"
                :value="item.warehouseId">
                </el-option>
            </el-select>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBack">确 定</el-button>
    </span>
    </el-dialog>
    <!-- 物流信息 -->
    <el-dialog
    v-show="isShowExpress"
    title="提示"
    :visible.sync="dialogVisibleWl"
    width="40%"
    >
     <template>
        <li class="checkYep">{{expressCheck}}</li>
      </template>
    <div v-for="item in expressDetail" :key="item.action">
    <ul>
      <li class="exp">{{item.AcceptStation}}</li>
    </ul>
    </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleWl = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisibleWl = false">确 定</el-button>
  </span>
</el-dialog>
</div>
</template>
<script>

// import {baseUrl} from '../../utils/baseUrl.js'
import {getAfterShopOrderDetails,examine,setFee,getAllWar,back,enterGoods,enterPay,enterRejection,getExpressDetail} from '../../api/selectMall.js'
export default {
 
    data(){
      
        return{
              expressCheck:'',
             expressDetail:'',
            dialogVisible:false,
            dialogVisibleWl: false,
            isShowExpress:false,
            // baseUrl,
            isClose:true,
            details: {
              
            },
            warehouseList:[],
            backForm: {
                warehouseId: '',
            },
            backFormRules: {
                warehouseId: [
                    {required: true, message: '请选择仓库', trigger: 'blur'},
                ],
            }
           
        }
    },
    created(){
        this.getDetaails()
        getAllWar().then(({data})=>{
            this.warehouseList = data.data
        })
    },
    
    methods: {
           async onShowExpress(row){
        this.dialogVisibleWl = true
        this.isShowExpress = true
        const {data} = await getExpressDetail({'orderId':this.details.orderId})
        this.expressCheck = data.data.expResult.Traces[data.data.expResult.Traces.length-1]
        this.expressCheck = this.expressCheck.AcceptStation
			  this.expressDetail = data.data.expResult.Traces
			  this.expressDetail.reverse().shift()
      },
       async getDetaails(){
            
            const {data} = await getAfterShopOrderDetails({prodBackId:this.$route.query.id})
            if(data.code == 0){
                this.details = data.data
            }
        },
        handleAvatarSuccess(res,file,type){
            this.addFrom.headImgUrl = file.response.data[0].src;
        },
        submitForm(){
            this.addFrom.details = this.refs.teacherDetailsRef.msg
            this.refs.addFromRef.validate(async validate=>{
                if(!validate)return
                if(this.isSubmit)return
                this.isSubmit  = true
                const {data} = await teacherAdd(this.addFrom)
                if(data.code == 0){
                    this.router.push('/teacherAdmin')
                    this.message.success(data.msg)
                }else{
                    this.message.warning(data.msg)
                    
                    this.isSubmit = false
                }
            })
        },
        handleClose(){
           this.$refs.backFormRef.resetFields()
        },
        checkOrder(){
           this.$router.push('/selectMall/order/detail?id='+this.details.orderId)
        },
        // 修改退款金额
        setPrice(){
             this.isClose = true
             this.$prompt('请输入退款金额', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /\S/,
            closeOnClickModal:false,
            inputErrorMessage: '请输入退款金额',
             beforeClose: (action, instance, done) => {
                 console.log(action)
                if(action == 'confirm'){
                    if(this.isClose){
                       done()
                     
                    }
                }else{
                     done()
                }
            }
           }).then( async({ value }) => {
                if(value > this.details.totalFee){
                    this.$nextTick(()=>{
                        this.isClose = false
                    })
                     
                    this.$message({
                            type: 'warning',
                            message: '退款金额超出范围,请重新输入退款金额'
                    
                         })
                  return
                }
                const {data} = await setFee({
                    backProdId:this.details.prodBackId,
                    endFee:value
                })
                if(data.code == 0){
                     this.$message.success({
                              message: data.msg,
                        })
                    this.getDetaails()
                }else{
                    this.$message({
                        type: 'warning',
                        message: data.msg
                    })
                }
            }).catch(() => {
             
            });
        },
        // 选择回退仓库
        backWarehouse(){
            this.dialogVisible = true
        
        },
        enter(){
            // 确认收货
            this.$confirm('确定已经收货了吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const {data} = await enterGoods({prodBackId:this.$route.query.id,})
                    if(data.code == 0){
                        this.$message.success({
                            message: data.msg,
                            
                        })
                        this.getDetaails()
                    }else{
                        this.$message({
                            message: data.msg,
                            type: 'warning'
                     
                        })
                    }
                })
        },
        payment(){
            // 打款
             this.$confirm('确定打款吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const {data} = await enterPay({prodBackId:this.$route.query.id,})
                    if(data.code == 0){
                        this.$message.success({
                            message: data.msg,
                            
                        })
                        this.getDetaails()
                    }else{
                        this.$message({
                            message: data.msg,
                            type: 'warning'
                     
                        })
                    }
                })
        },
        // 强制拒绝
        rejection(){
            this.$prompt('请输入强制拒绝原因', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S/,
                inputErrorMessage: '请输入强制拒绝原因'
                }).then(async ({ value }) => {
                     const {data} = await enterRejection({prodBackId:this.$route.query.id,refuse:value})
                    if(data.code == 0){
                       this.$message.success({
                            message: data.msg,
                            
                        })
                        this.getDetaails()
                    }else{
                         this.$message({
                            message: data.msg,
                            type: 'warning'
                     
                        })
                    }
                }).catch(() => {
                   
                });
        },
        submitBack(){
            this.$refs.backFormRef.validate(async validate=>{
                if(!validate)return
                if(this.isSubmit)return
                this.isSubmit = true
                this.backForm.prodBackId = this.details.prodBackId
                const {data} = await back(this.backForm)
                if(data.code == 0){
                    this.$message.success({
                            message: data.msg,
                            
                        })
                    this.getDetaails()
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: data.msg
                    })
                }
                setTimeout(() => {
                     this.isSubmit = false
                }, 500);
            })
        },
        // 审核通过
        isPass(status){
            if(status == 1){
                this.$confirm('确定通过审核吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const {data} = await examine({prodBackId:this.$route.query.id,isAudit:1})
                    if(data.code == 0){
                        this.$message.success({
                            message: data.msg,
                            
                        })
                        this.getDetaails()
                    }else{
                        this.$message({
                            message: data.msg,
                            type: 'warning' 
                        })
                    }
                })
            }else{
                 this.$prompt('请输入退款原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /\S/,
                    inputErrorMessage: '请输入退款原因'
                }).then(async ({ value }) => {
                     const {data} = await examine({prodBackId:this.$route.query.id,isAudit:0,refuse:value})
                    if(data.code == 0){
                        this.$message.success({
                              message: data.msg,
                        })
                        this.getDetaails()
                    }else{
                         this.$message({
                            message: data.msg,
                            type: 'warning'
                     
                        })
                    }
                }).catch(() => {
                   
                });
            }
          
        
        },
    }
}
</script>

<style scoped>
.item{
    display: flex;
}
.avatar-uploader-icon {
    border: 1px dashed  #eee;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
.avatar{
    width: 150px;
    height: 150px;
   
}
.box-card{
   padding-bottom: 50px;
   
}
.title_box{
    font-weight: bold;
    font-size: 20px;
    border-left: 8px solid #222222;
    padding: 0 5px;
}
.item_box{
    width: 50%;
  
    display: flex;
}
</style>